import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import {
  List,
  ListDivider,
  ListGroup,
  ListItem,
  ListItemGraphic,
  ListSubheader
} from "@cdk-uip/react-list";
import { FormattedMessage } from "react-intl";
//import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
//import { LinkContainer } from "react-router-bootstrap";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import UserSearch from "../UserSearch/UserSearchContainer";
import config from "../config/app.conf.json";
import { Link } from "react-router-dom";

class Home extends React.Component {
  gotoPendingActivations = () => {
    this.props.history.push("/pending");
  };
  gotoSignedUpUsers = () => {
    this.props.history.push("/signed-up-users");
  };
  gotoApprovedUsers = () => {
    this.props.history.push("/approved-users");
  };
  gotoMetricsRegistration = () => {
    this.props.history.push("/metrics");
  };
  goToEntityApprovals = () => {
    this.props.history.push("/pending-orgs");
  };
  gotoEntityConfig = () => {
    this.props.history.push("/org-management");
  };
  gotoRestoreOrg = () => {
    this.props.history.push("/restore-orgs");
  };
  gotoSubscriptionConfig = () => {
    this.props.history.push("/subscriptions");
  };
  gotoApiAdmin = () => {
    this.props.history.push("/api-admin");
  };
  gotoApiBundleAdmin = () => {
    if(config?.isBundleManagementEnabled){
      this.props.history.push("/bundle-management");
    }
    else{
      this.props.history.push("/bundle-management/view-all");
    }
  };
  gotoSpecReview = () => {
    this.props.history.push("/spec-review");
  };
  gotoApiProviderManagement = () => {
    this.props.history.push("/api-provider-management");
  };

  gotoExportEntities = () => {
    this.props.history.push("/export-orgs");
  };
  gotoSpecMetrics = () => {
    this.props.history.push("/spec-metrics");
  };
  gotoAppMetrics = () => {
    this.props.history.push("/app-metrics");
  };
  gotoApiProviders = () => {
    this.props.history.push("/api-providers");
  };
  gotoBulkUserUpload = () => {
    this.props.history.push("/bulk-user-upload");
  };
  gotoBulkSubscriptions = () => {
    this.props.history.push("/bulk-subscriptions");
  };
  gotoCertificationManagements = () => {
    this.props.history.push("/certification-management");
  };
  gotoAgreements = () => {
    this.props.history.push("/agreements");
  };
  gotoApiUsage = () => {
    this.props.history.push("/api-usage");
  };

  gotoSubscriptionReport = () => {
    this.props.history.push("/subscription-reports");
  };

  goToMarketplaceSolutions = () => {
    window.location.assign(`${config.apps.marketplace}/admin/solutions`);
  };
  goToViewDashboard = () => {
    window.open(`${config.apps.billing_dashboard}`,'_blank');
  };

  goToMarketplaceSolutionSubscriptions = () => {
    window.location.assign(`${config.apps.marketplace}/admin/subscriptions`);
  };

  goToMarketplaceSubscriptionsData = () => {
    window.location.assign(
      `${config.apps.marketplace}/admin/subscriptions-data`
    );
  };

  goToMarketplaceSolutionReviews = () => {
    window.location.assign(`${config.apps.marketplace}/admin/review`);
  };

  goToMarketplaceSolutionLeads = () => {
    window.location.assign(`${config.apps.marketplace}/admin/leads`);
  };
  gotoMonetizationReport = () => {
    this.props.history.push("/monetization-reports");
  };

  gotoUsageReport = () => {
    this.props.history.push("/usage-reports");
  };

  gotoAudit = () => {
    this.props.history.push("/audit-reports");
  };

  goToApiIntegrationManagement = () => {
    this.props.history.push("/api-integration-management");
  };

  goToLastTransactions = () => {
    this.props.history.push("/last-transactions-report");
  };

  goToPaymentCodeManagement = () => {
    this.props.history.push("/payment-codes");
  };

  render() {
    return (
      <Card className={"s-content-route--home c-card-home"}>
        <CardHeader>
          <CardTitle large>Welcome to Your Accounts Admin App</CardTitle>
          <CardSubtitle>
            Let's decide on Users who are pending activations, manage some
            Organizations, Subscriptions or view some metrics...
          </CardSubtitle>
        </CardHeader>
        <CardText>
          {/* #START CARDTEXT COPY BLOCK. */}

          <LayoutGrid nested className={"c-layout-section__home-grid"}>
            <LayoutGridCell
              span={4}
              className={
                "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--instructional"
              }
            >
              {/* 3 ICONS STACKED + CSS make for a simple illustration/icon stack for the balancing the nav list */}
              <Icon className={"c-layout-section__home-grid-cell-icon--full"}>
                settings
              </Icon>
              <Icon className={"c-layout-section__home-grid-cell-icon--full"}>
                account_circle
              </Icon>
              <Icon className={"c-layout-section__home-grid-cell-icon--full"}>
                radio_button_unchecked
              </Icon>
              {/* #END 3 ICONS STACKED */}
            </LayoutGridCell>
            <LayoutGridCell
              span={8}
              className={
                "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
              }
            >
              {/* #START ADMIN TASK LIST */}
              <ListGroup>
                <ListSubheader className="users-heading">
                  <div style={{ display: "inline-block" }}>Users</div>
                  <UserSearch />
                </ListSubheader>
                <List>
                  {/* <ListItem onClick={this.gotoPendingActivations}>
                    <ListItemGraphic>
                      <Icon>people</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.loadusers"
                      defaultMessage="Choose Users Pending Activation"
                    />
                  </ListItem> */}
                  <ListItem onClick={this.gotoSignedUpUsers}>
                    <ListItemGraphic>
                      <Icon>people</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.signedUpUsers"
                      defaultMessage="Signed Up Users"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoApprovedUsers}>
                    <ListItemGraphic>
                      <Icon>people</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.approvedUsers"
                      defaultMessage="Approved Users"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoAgreements}>
                    <ListItemGraphic>
                      <Icon>folder</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.Agreements"
                      defaultMessage={"Agreements"}
                    />
                  </ListItem>
                </List>
                <ListDivider style={{ display: "block" }} />
                <ListSubheader>Management & Settings</ListSubheader>
                <List>
                  <ListItem onClick={this.goToEntityApprovals}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.entitiesapproval"
                      defaultMessage="Unverified Organizations"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoEntityConfig}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.entitiesconfig"
                      defaultMessage="Organizations Setup"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoRestoreOrg}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.restoreOrganization"
                      defaultMessage="Restore org"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoSubscriptionConfig}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.subscriptionconfig"
                      defaultMessage="Subscription Setup"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoApiAdmin}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.apiadmin"
                      defaultMessage="API Gateway V2 Admin"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoApiBundleAdmin}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.apibundleadmin"
                      defaultMessage="API Bundle Management Admin"
                    />
                  </ListItem>

                  <ListItem onClick={this.goToApiIntegrationManagement}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.apiIntegrattionManagement"
                      defaultMessage="API Integration Management"
                    />
                  </ListItem>

                  <ListItem onClick={this.gotoAudit}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.audit"
                      defaultMessage="Audit Report"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoExportEntities}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.exportEntitiesManagement"
                      defaultMessage="Export Organizations"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoBulkUserUpload}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.bulkUserUpload"
                      defaultMessage="Bulk User Upload"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoBulkSubscriptions}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.bulkSubscriptions"
                      defaultMessage="Bulk Subscriptions"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoCertificationManagements}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.certficationManagement"
                      defaultMessage="Certification Management"
                    />
                  </ListItem>
                  <ListItem onClick={this.goToPaymentCodeManagement}>
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.paymentCodeManagement"
                      defaultMessage="Payment Code Management"
                    />
                  </ListItem>
                </List>

                <ListDivider style={{ display: "block" }} />
                <ListSubheader data-cy="marketplace_admin_account">
                  <FormattedMessage
                    id="Home.action.marketplace.adminsection.title"
                    defaultMessage="Marketplace Admin Account"
                  />
                </ListSubheader>

                <List>
                  <ListItem
                    onClick={this.goToMarketplaceSolutions}
                    data-cy="review_solution_listings"
                  >
                    <ListItemGraphic>
                      <Icon>apps</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.marketplace.solutions"
                      defaultMessage="Review Apps Listings"
                    />
                  </ListItem>
                  <ListItem
                    onClick={this.goToMarketplaceSolutionSubscriptions}
                    data-cy="review_buyer_subscriptions"
                  >
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.marketplace.solutions.subscriptions"
                      defaultMessage="Review Buyer Subscriptions"
                    />
                  </ListItem>
                  <ListItem
                    onClick={this.goToMarketplaceSolutionReviews}
                    data-cy="moderate_user_ratings"
                  >
                    <ListItemGraphic>
                      <Icon>star</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.marketplace.solutions.reviews"
                      defaultMessage="Moderate User Ratings"
                    />
                  </ListItem>
                  <ListItem
                    onClick={this.goToMarketplaceSolutionLeads}
                    data-cy="review_solution_leads"
                  >
                    <ListItemGraphic>
                      <Icon>people</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.marketplace.solutions.leads"
                      defaultMessage="Review Apps Leads"
                    />
                  </ListItem>
                  <ListItem
                    onClick={this.goToMarketplaceSubscriptionsData}
                    data-cy="review_subscriptions_data"
                  >
                    <ListItemGraphic>
                      <Icon>settings</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.marketplace.solutions.subscriptionsData"
                      defaultMessage="Subscriptions Data"
                    />
                  </ListItem>
                  <ListItem
                    onClick={this.goToLastTransactions}
                    data-cy="last_transactions"
                  >
                    <ListItemGraphic>
                      <Icon>note</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.lastTransactions"
                      defaultMessage="Last Transactions Report"
                    />
                  </ListItem>
                </List>
                <ListDivider style={{ display: "block" }} />
                <ListSubheader data-cy="billing">
                  <FormattedMessage
                    id="Home.action.billing"
                    defaultMessage="API Billing Dashboard"
                  />
                </ListSubheader>
                <List>
                  <ListItem
                    onClick={this.goToViewDashboard}
                    data-cy="view_dashboard"
                  >
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.viewdashboard"
                      defaultMessage="View Dashboard"
                    />
                  </ListItem>
                </List>

                <ListDivider style={{ display: "block" }} />
                <ListSubheader>Metrics</ListSubheader>
                <List>
                  <ListItem onClick={this.gotoMetricsRegistration}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.metricsregistration"
                      defaultMessage="View User Metrics"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoAppMetrics}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.appmetrics"
                      defaultMessage="View Marketplace App Metrics"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoApiUsage}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.apiusage"
                      defaultMessage="View API Usage"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoSubscriptionReport}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.subscriptionReports"
                      defaultMessage="View Subscription Reports"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoMonetizationReport}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.monetizationReports"
                      defaultMessage="View Monetization Reports"
                    />
                  </ListItem>
                  <ListItem onClick={this.gotoUsageReport}>
                    <ListItemGraphic>
                      <Icon>insert_chart</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.usageReport"
                      defaultMessage="View Usage Report"
                    />
                  </ListItem>
                </List>
              </ListGroup>
              {/* #END ADMIN TASK LIST */}
            </LayoutGridCell>
            <LayoutGridCell span={12} />
          </LayoutGrid>

          {/* #END CARDTEXT COPY BLOCK. */}
        </CardText>
      </Card>
    );
  }
}
export default withAuth(Home);
