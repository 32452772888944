import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "./entityActions";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
// UI COMPONENTS
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Select } from "@cdk-uip/react-select";
import { TextField } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { Icon } from "@cdk-uip/react-icon";
import { LoadingButton } from "../components/LoadingButton";
import { ButtonIcon, Button } from "@cdk-uip/react-button";
import copy from "copy-to-clipboard";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { AgreementsManagement } from "./AgreementsManagement";
import FeatureGatesDialog from "./FeatureGatesDialog";
import NamespaceDialog from "./NamespaceDialog";
import DeleteOrganizationDialog from "./DeleteOrganizationDialog";
import EnhancedFlagsDialog from "./EnhancedFlagsDialog";

const NAME_TYPE = "name";
const DOMAIN_TYPE = "domain";
const ID = 'ID'
const UNAVALIABLE = "n/a";

class EntitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchType: NAME_TYPE,
      searchInProgress: false,
      lastSearch: "",
      snack: false,
      isSearchDisabled: true,
      showAgreementDialog: false,
      showFeatureGatesDialog: false,
      showNameSpaceDialog: false,
      entityToEditNameSpace: null,
      agreementRequestForEntity: {},
      entityToEditFeatureGates: null,
      orgSearchData: null,
      verifiedOverrides: {},
      showDeleteOrganizationDialog: false,
      showEnhancedFlagsDialog: false,
      entityToEditEnhancedFlags: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entityManagement,
      entityManagement: {
        searchData,
        searchError,
        verifyEntityId,
        verifyIsVerified
      }
    } = this.props;
    if (prevProps.entityManagement !== entityManagement) {
      if (
        prevProps.entityManagement.searchData !== entityManagement.searchData
      ) {
        if (searchData.organizations.length > 0) {
          this.setState({
            searchString: "",
            lastSearch: prevState.searchString,
            searchInProgress: false,
            isSearchDisabled: true,
            orgSearchData: entityManagement.searchData
          });
        } else {
          this.setState({
            lastSearch: prevState.searchString,
            searchInProgress: false
          });
        }
      } else if (searchError) {
        this.setState({
          searchInProgress: false
        });
      }
      if (
        prevProps.entityManagement.verifyEntityId !== verifyEntityId ||
        prevProps.entityManagement.verifyIsVerified !== verifyIsVerified
      ) {
        // a verify request completed
        if (verifyIsVerified) {
          this.updateSnack("Organization verified");
        } else {
          this.updateSnack("Organization unverified");
        }
        this.setState(prevState => ({
          verifiedOverrides: {
            ...prevState.verifiedOverrides,
            [verifyEntityId]: verifyIsVerified
          }
        }));
      }
    }
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  onSubmit = () => {
    const { searchString, searchType } = this.state;
    const {
      auth: { accessToken }
    } = this.props;
    let trimmedSearch = searchString.trim();
    if (searchType.length) {
      if (searchType === NAME_TYPE) {
        this.props.searchEntity({
          accessToken,
          name: trimmedSearch
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === DOMAIN_TYPE) {
        this.props.searchEntity({
          accessToken,
          domain: trimmedSearch
        });
        this.setState({ searchInProgress: true });
      }else if (searchType === ID) {
        this.props.searchEntity({
          accessToken,
          id: trimmedSearch
        });
        this.setState({ searchInProgress: true });
      }
    }
  };

  uploadAgreement = (entity, agreementType, event) => {
    const fileToUpload = event.target.files[0];
    if (fileToUpload) {
      const {
        auth: { accessToken }
      } = this.props;
      let formData = new FormData();
      formData.append("agreementType", agreementType);
      formData.append("uploadedFile", fileToUpload);
      this.props.uploadAgreement({ accessToken, entity, formData });
    }
  };

  viewAgreement = (entity, agreementType) => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.viewAgreement({ accessToken, entity, agreementType });
  };

  deleteAgreement = (entity, agreementType) => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.deleteAgreement({ accessToken, entity, agreementType });
  };

  deleteOrganization = entity => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.deleteOrganization({ accessToken, entity });
  };

  replaceAgreement = (entity, agreementType, event) => {
    const fileToUpload = event.target.files[0];
    if (fileToUpload) {
      const {
        auth: { accessToken }
      } = this.props;
      let formData = new FormData();
      formData.append("agreementType", agreementType);
      formData.append("uploadedFile", fileToUpload);
      this.props.replaceAgreement({ accessToken, entity, formData });
    }
  };

  updateSnack = text => {
    this.setState({
      snack: text
    });
  };

  openAgreementManagement = entity => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getAgreementsDetailsAndOpenDialog({ accessToken, entity });
  };

  openFeatureGatesDialog = entity => {
    this.setState({
      showFeatureGatesDialog: true,
      entityToEditFeatureGates: entity
    });
  };

  openNameSpaceDialog = entity => {
    this.setState({
      showNameSpaceDialog: true,
      entityToEditNameSpace: entity
    });
  };

  openDeleteOrganizationDialog = entity => {
    this.setState({
      showDeleteOrganizationDialog: true,
      entityToDeleteOrganization: entity
    });
  };

  openEnhancedFlagsDialog = entity => {
    this.setState({
      showEnhancedFlagsDialog: true,
      entityToEditEnhancedFlags: entity
    });
  };

  updateFeatureGates = async (entity, featureGates) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const data = featureGates ? JSON.parse(featureGates) : undefined;
      const { response } = await this.props.updateFeatureGates({
        accessToken,
        entity,
        data
      });
      console.log(response);
      if (response.featureGates !== undefined) {
        this.updateOrgData(entity, response.featureGates);
      } else if (response.message) {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error("Invalid JSON syntax");
      } else {
        throw error;
      }
    }
  };

  updateNameSpaces = async (entity, namespaces) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const orgNamespace = namespaces ? JSON.parse(namespaces) : undefined;
      const data = {
        namespaces: orgNamespace,
        version: entity && entity.version
      };
      const { response } = await this.props.updateNameSpaces({
        accessToken,
        entity,
        data
      });

      if (response.namespaces !== undefined) {
        this.updateOrg(entity, response.namespaces);
      } else if (response.message) {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error("Invalid Array of string Syntax");
      } else {
        throw error;
      }
    }
  };

  updateOrgData = (entity, featureGates) => {
    const orgData = JSON.parse(JSON.stringify(this.state.orgSearchData));
    const organizationEntity = orgData.organizations.find(
      f => f.id === entity.id
    );
    organizationEntity.featureGates = featureGates;
    this.setState({ orgSearchData: orgData });
  };

  updateOrg = (entity, namespaces) => {
    const orgData = JSON.parse(JSON.stringify(this.state.orgSearchData));
    const organizationEntity = orgData.organizations.find(
      f => f.id === entity.id
    );
    organizationEntity.namespaces = namespaces;
    organizationEntity.version = organizationEntity.version + 1;
    this.setState({ orgSearchData: orgData });
  };

  updateEnhancedFlags = async (entity, enhancedFlags) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const data = {
        enhancedFlags: enhancedFlags,
        version: entity && entity.version
      };
      const { response } = await this.props.updateEnhancedFlags({
        accessToken,
        entity,
        data
      });
      this.closeFlagsDialog()
      if (response.enhancedFlags !== undefined) {
        this.updateOrgFlags(response);       
      }
    } catch (error) {
        throw error;
    }
  };

  updateOrgFlags = (entity) => {
    const orgData = JSON.parse(JSON.stringify(this.state.orgSearchData));
    const organizationEntity = orgData.organizations.find(
      f => f.id === entity.id
    );
    organizationEntity.enhancedFlags = JSON.stringify(entity.enhancedFlags);
    organizationEntity.version = entity.version
    this.setState({ orgSearchData: orgData });
  };

  closeAgreementDialog = () => {
    this.props.closeAgreementDialog();
  };

  closeFeatureGatesDialog = () => {
    this.setState({
      showFeatureGatesDialog: false
    });
  };

  closeNameSpaceDialog = () => {
    this.setState({
      showNameSpaceDialog: false
    });
  };

  closeDeleteOrganizationDialog = () => {
    this.setState({
      showDeleteOrganizationDialog: false
    });
  };

  closeFlagsDialog = () => {
    this.setState({
      showEnhancedFlagsDialog: false
    });
  };

  render() {
    let searchData = this.state.orgSearchData;

    const {
      searchInProgress,
      lastSearch,
      searchType,
      searchString,
      isSearchDisabled
    } = this.state;

    return (
      <React.Fragment>
        {searchInProgress ||
        this.props.entityManagement.showUploadProgress ||
        this.props.entityManagement.showRetrieveProgress ||
        this.props.entityManagement.showDeleteProgress ||
        this.props.entityManagement.showReplaceProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            {this.props.entityManagement.showUploadProgress ||
            this.props.entityManagement.showRetrieveProgress ||
            this.props.entityManagement.showDeleteProgress ||
            this.props.entityManagement.showReplaceProgress ? (
              <Headline>{this.props.entityManagement.progressMessage}</Headline>
            ) : (
              <Headline>
                Searching...
                <br />
                Showing related Organizations
                <br />
                Please wait...
              </Headline>
            )}
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Search Organizations</CardTitle>
              <CardSubtitle>Search by name or email domain or entity id.</CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="entitySearch"
                    name="entitySearch"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    value={searchString}
                    onChange={e => {
                      this.onInputChange(e.target.value, "searchString");
                      this.setState({
                        isSearchDisabled: e.target.value.trim().length < 3
                      });
                    }}
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <Select
                    label="Search By:"
                    className="c-search-input--type"
                    value={searchType}
                    onChange={e =>
                      this.onInputChange(e.target.value, "searchType")
                    }
                    allowEmpty={false}
                    required
                  >
                    <option value={NAME_TYPE}>Organization Name</option>
                    <option value={DOMAIN_TYPE}>Email Domain</option>
                    <option value={ID}>Organization ID</option>
                  </Select>
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    disabled={isSearchDisabled}
                    loadingValue={"Searching..."}
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search
                  </LoadingButton>
                </FluidItem>
              </Fluid>
              <div className="hint">
                {searchType === DOMAIN_TYPE && <span>Enter full or partial email addresses or domains.</span>}
                {searchType === NAME_TYPE && <span>Enter full or partial names.</span>}
                {searchType === ID && <span>Enter Organisation Id.</span>}
              </div>
            </CardText>
            {searchData ? (
              <CardText>
                <h2>Result Returned For: "{lastSearch}"</h2>
                {!!searchData.organizations &&
                searchData.organizations.length > 0 ? (
                  <div className="c-entity-search--container">
                    {searchData.organizations.map(entity => (
                      <EntityInformation
                        entity={entity}
                        key={entity.id}
                        updateSnack={this.updateSnack}
                        openAgreementManagement={this.openAgreementManagement}
                        editFeatureGates={this.openFeatureGatesDialog}
                        editNamespace={this.openNameSpaceDialog}
                        deleteOrganization={this.openDeleteOrganizationDialog}
                        editEnhancedFlags={this.openEnhancedFlagsDialog}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="cdk-entity-search--result">
                    <h3>{`${lastSearch} not found`}</h3>
                  </div>
                )}
              </CardText>
            ) : (
              ""
            )}
          </Card>
        </Elevation>
        <AgreementsManagement
          open={this.props.entityManagement.showAgreementDialog ? true : false}
          entity={
            this.props.entityManagement.agreementRequestForEntity
              ? this.props.entityManagement.agreementRequestForEntity
              : {}
          }
          closeAgreementDialog={this.closeAgreementDialog}
          uploadAgreement={this.uploadAgreement}
          viewAgreement={this.viewAgreement}
          deleteAgreement={this.deleteAgreement}
          replaceAgreement={this.replaceAgreement}
          shouldShow={this.props.entityManagement.shouldShow}
          snackbarMessage={this.props.entityManagement.snackbarMessage}
        />
        <FeatureGatesDialog
          open={this.state.showFeatureGatesDialog}
          entity={this.state.entityToEditFeatureGates}
          saveChanges={this.updateFeatureGates}
          onClose={this.closeFeatureGatesDialog}
        />
        <NamespaceDialog
          open={this.state.showNameSpaceDialog}
          entity={this.state.entityToEditNameSpace}
          saveChanges={this.updateNameSpaces}
          onClose={this.closeNameSpaceDialog}
        />
        <DeleteOrganizationDialog
          open={this.state.showDeleteOrganizationDialog}
          entity={this.state.entityToDeleteOrganization}
          onClose={this.closeDeleteOrganizationDialog}
          deleteOrganization={this.deleteOrganization}
        />
        {this.state.showEnhancedFlagsDialog && 
        <EnhancedFlagsDialog
          open={this.state.showEnhancedFlagsDialog}
          entity={this.state.entityToEditEnhancedFlags}
          saveChanges={this.updateEnhancedFlags}
          onClose={this.closeFlagsDialog}
        />
        }
        <TemporarySnackbar
          show={this.state.snack.length}
          message={this.state.snack}
          onClose={() => this.setState({ snack: "" })}
          timeout={1000}
        />
      </React.Fragment>
    );
  }
}

const EntityInformation = ({
  entity,
  updateSnack,
  openAgreementManagement,
  editFeatureGates,
  editNamespace,
  deleteOrganization,
  editEnhancedFlags
}) => {
  const copyToClipboard = id => {
    let result = copy(id);
    if (result) {
      updateSnack("Copied Organization ID to Clipboard");
    }
  };
  const generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };
  return (
    <div className="c-entity-search--result">
      <div className="c-entity-search--result-title">
        <h2>{entity.name}</h2>
        <Icon
          onClick={() => copyToClipboard(entity.id)}
          title="Copy Organization ID"
        >
          content_copy
        </Icon>
      </div>

      <ul className="c-entity-search--result-items">
        <LayoutGrid nested className={"c-layout-section__home-grid"}>
          <LayoutGridCell
            span={8}
            className={
              "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
            }
          >
            <EntityListItem
              id={entity.id}
              text={entity.id}
              title="Organization ID"
            />
            {entity && entity.emailDomains && (
              <EntityListItem
                text={entity.emailDomains}
                title="Email Domains"
              />
            )}

            <EntityListItem text={entity.phone} title="Phone Number" />
            <EntityListItem text={entity.website} title="Website" />
            <EntityListItem
              text={
                entity.address ? generateAddress(entity.address) : undefined
              }
              title="Address"
            />
            <EntityListItem text={entity.belongsTo} title="Belongs to" />
          </LayoutGridCell>
          <LayoutGridCell>
            <Button
              className="manage_agreements-dialog-button"
              outlined
              onClick={() => openAgreementManagement(entity)}
            >
              Manage Agreements
            </Button>

            <Icon
              className="org_search_delete_button"
              onClick={() => deleteOrganization(entity.id)}
              title="Delete organization"
            >
              delete
            </Icon>

            <React.Fragment>
              <Button
                className="manage_agreements-dialog-button"
                outlined
                onClick={() => editFeatureGates(entity)}
              >
                Manage Feature Gates
              </Button>
            </React.Fragment>

            <Button
              className="manage_agreements-dialog-button"
              outlined
              onClick={() => editNamespace(entity)}
            >
              Manage NameSpaces
            </Button>
            <Button
              className="manage_flags-dialog-button"
              outlined
              onClick={() => editEnhancedFlags(entity)}
            >
              Manage Enhanced Flags
            </Button>
          </LayoutGridCell>
        </LayoutGrid>
      </ul>
    </div>
  );
};

const EntityListItem = ({ text, title, id }) => (
  <li className="c-search-list-item">
    <Fluid halign="left">
      <div className="c-search-list-item__title">{title}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text" id={id}>
        {text ? text : UNAVALIABLE}
      </div>
    </Fluid>
  </li>
);

export default connect(
  state => ({
    entityManagement: state.entityManagement
  }),
  actions
)(withAuth(EntitySearch));
