import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "../EntityManagement/entityActions";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
// UI COMPONENTS
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Select } from "@cdk-uip/react-select";
import { TextField } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { LoadingButton } from "../components/LoadingButton";
import { ButtonIcon } from "@cdk-uip/react-button";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Button } from "@cdk-uip/react-button";

const NAME_TYPE = "name";
const DOMAIN_TYPE = "domain";
const ID = 'ID'
const UNAVALIABLE = "n/a";

const sortByEntityName = (entity1, entity2) => {
  const entityName1 = entity1.name.toLowerCase(),
    entityName2 = entity2.name.toLowerCase();
  if (entityName1 < entityName2) return -1;
  if (entityName1 > entityName2) return 1;
  return 0;
};

class SearchAndLinkEntityWithUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchType: NAME_TYPE,
      searchInProgress: false,
      lastSearch: "",
      snack: false,
      isSerarchDisabled: true,
      selectedEntity: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entityManagement,
      entityManagement: { searchDataNew, searchError }
    } = this.props;
    if (prevProps.entityManagement !== entityManagement) {
      if (
        prevProps.entityManagement.searchDataNew !==
        entityManagement.searchDataNew
      ) {
        if (searchDataNew.organizations.length > 0) {
          this.setState({
            searchString: "",
            lastSearch: prevState.searchString,
            searchInProgress: false,
            isSerarchDisabled: true
          });
        } else {
          this.setState({
            lastSearch: prevState.searchString,
            searchInProgress: false
          });
        }
      } else if (searchError) {
        this.setState({
          searchInProgress: false
        });
      }
    }
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  selectEntity = (entityID, entityName) => {
    const { onClick } = this.props;
    this.setState({
      selectedEntity: entityID
    });
    onClick(JSON.stringify({ id: entityID, name: entityName }));
  };

  onSubmit = () => {
    const { searchString, searchType } = this.state;
    const {
      auth: { accessToken },
      sId
    } = this.props;
    let trimmedSearch = searchString.trim();
    if (searchType.length) {
      if (searchType === NAME_TYPE) {
        this.props.searchEntityForUserActivation({
          accessToken,
          name: trimmedSearch,
          sourceId: sId
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === DOMAIN_TYPE) {
        this.props.searchEntityForUserActivation({
          accessToken,
          domain: trimmedSearch,
          sourceId: sId
        });
        this.setState({ searchInProgress: true });
      }else if (searchType === ID) {
        this.props.searchEntityForUserActivation({
          accessToken,
          id: trimmedSearch,
          sourceId: sId
        });
        this.setState({ searchInProgress: true });
      }
    }
  };

  updateSnack = text => {
    this.setState({
      snack: text
    });
  };

  addUserToEntity = entity => {
    const {
      auth: { accessToken },
      uid
    } = this.props;
    this.props.addUserToEntity({ entity, uid, accessToken });
    this.setState({ entity: entity, uid: uid });
  };

  render() {
    const {
      entityManagement: { searchDataNew, sourceId = {} },
      sId,
      isAdding,
      entityId
    } = this.props;
    const {
      searchInProgress,
      lastSearch,
      searchType,
      searchString,
      isSerarchDisabled
    } = this.state;
    return (
      <React.Fragment>
        {searchInProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>
              Searching Organisations
              <br />
              Showing related Organisations
              <br />
              Please wait...
            </Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Organisation Search</CardTitle>
              <CardSubtitle>
                Search for an Organisation by name. Enter at least 3 characters
                to search.{" "}
              </CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="entitySearch"
                    name="entitySearch"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    label="Organisation Search"
                    value={searchString}
                    onChange={e => {
                      this.onInputChange(e.target.value, "searchString");
                      this.setState({
                        isSerarchDisabled: e.target.value.trim().length < 3
                      });
                    }}
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <Select
                    label="Search By:"
                    className="c-search-input--type"
                    value={searchType}
                    onChange={e =>
                      this.onInputChange(e.target.value, "searchType")
                    }
                    allowEmpty={false}
                    required
                  >
                    <option value={NAME_TYPE}>Organization Name</option>
                    <option value={DOMAIN_TYPE}>Email Domain</option>
                    <option value={ID}>Organization ID</option>
                  </Select>
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    disabled={isSerarchDisabled}
                    loadingValue={"Searching..."}
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search for Organisation
                  </LoadingButton>
                </FluidItem>
              </Fluid>
              <div className="hint">
                    {searchType === DOMAIN_TYPE && <span>
                      Organisation email domain (ex: <strong>fortellis.io</strong>{" "}
                      or <strong>cdk.com</strong>) accepts partials and case
                      mismatch.
                    </span>}
                    {searchType === NAME_TYPE && <span> 
                    Organisation name must be <strong>case-sensitive</strong>.
                    </span>}
                    {searchType === ID && <span>Enter Organisation Id.</span>}
              </div>
            </CardText>
            {searchDataNew && sourceId === sId ? (
              <CardText>
                <h2>Result Returned For: "{lastSearch}"</h2>
                {searchDataNew.organizations.length > 0 ? (
                  <div className="c-entity-search--container user-activation">
                    {searchDataNew.organizations
                      .sort(sortByEntityName)
                      .map((entity, index) => (
                        <EntityInformation
                          entity={entity}
                          key={index}
                          updateSnack={this.updateSnack}
                          selectEntity={this.selectEntity}
                          isSelected={this.state.selectedEntity === entity.id}
                          addUserToEntity={this.addUserToEntity}
                          isAdding={isAdding}
                          entityId={entityId}
                          associatedEntities={this.props.associatedEntities}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="cdk-entity-search--result">
                    <h3>{`${lastSearch} not found`}</h3>
                  </div>
                )}
              </CardText>
            ) : (
              ""
            )}
          </Card>
        </Elevation>
      </React.Fragment>
    );
  }
}

const EntityInformation = ({
  entity,
  updateSnack,
  selectEntity,
  isSelected,
  addUserToEntity,
  isAdding,
  entityId,
  associatedEntities
}) => {
  const generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };

  const isDisabled = associatedEntities.some(en => en.id === entity.id);

  return (
    <div
      className={classnames("c-entity-search--result", {
        "c-entity-search--result--selected": isSelected
      })}
      onClick={() => {
        selectEntity(entity.id, entity.name);
      }}
    >
      <div className="c-entity-search--result-title">
        <h2>{entity.name}</h2>
      </div>

      <ul className="c-entity-search--result-items">
        <LayoutGrid nested className={"c-layout-section__home-grid"}>
          <LayoutGridCell
            span={6}
            className={
              "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
            }
          >
            <EntityListItem id={entity.id} text={entity.id} title="Org ID" />
            {entity.emailDomains && (
              <EntityListItem
                text={
                  typeof entity.emailDomains === "string"
                    ? entity.emailDomains
                    : entity.emailDomains.join(", ")
                }
                title="Email Domains"
              />
            )}

            <EntityListItem text={entity.phone} title="Phone Number" />
            <EntityListItem text={entity.website} title="Website" />
            <EntityListItem
              text={
                entity.address ? generateAddress(entity.address) : undefined
              }
              title="Address"
            />
            <EntityListItem text={entity.belongsTo} title="Belongs to" />
          </LayoutGridCell>
          <LayoutGridCell className="user-profile-actions-container">
            {isAdding && entity.id === entityId ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                className="add-user-to-entity-button user-profile-actions"
                disabled={isDisabled}
                outlined
                onClick={() => addUserToEntity(entity)}
              >
                {!isDisabled
                  ? "Link user to Organisation"
                  : "User already linked"}
              </Button>
            )}
          </LayoutGridCell>
        </LayoutGrid>
      </ul>
    </div>
  );
};

const EntityListItem = ({ text, title, id }) => (
  <li className="c-search-list-item">
    <Fluid halign="left">
      <div className="c-search-list-item__title">{title}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text" id={id}>
        {text && typeof text === "object" ? UNAVALIABLE : text}
      </div>
    </Fluid>
  </li>
);

export default connect(
  state => ({
    associatedEntities: state.approvedUsersReducer.userEntities.list || [],
    entityManagement: state.entityManagement
  }),
  actions
)(withAuth(SearchAndLinkEntityWithUser));
